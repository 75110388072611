<template>
  <div>
    <v-row>
      <v-col>
        <span>
          Search Results for
          <b>"{{ searchText }}"</b>:
        </span>
      </v-col>
    </v-row>
    <v-row v-if="notFound">
      <v-col class="d-flex justify-center pt-3">
        <span v-if="searchText==''">Not Found</span>
        <span v-else class="font-weight-bold">Result not found for "{{ searchText }}"</span>
      </v-col>
    </v-row>
    <v-row v-if="!notFound">
      <v-col class="d-flex justify-center pt-3" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>

      <v-col v-if="!loading">
        <v-tabs v-model="tab" color="primary" class="mb-1">
          <v-tab>Semua</v-tab>
          <v-tab>Campaign</v-tab>
          <v-tab>Invoice</v-tab>
        </v-tabs>
        <v-card>
          <v-card-text>

            <v-tabs-items v-model="tab">
              <v-tab-item class="py-1">
                <v-list-item v-for="(itemAll, iAll) in searchResult" :key="iAll" two-line style="min-height: 52px">
                  <v-list-item-icon class="mb-0">
                    <v-icon color="primary" v-if="itemAll.type == 'Campaign'">mdi-cube</v-icon>
                    <v-icon color="success" v-if="itemAll.type == 'Invoice'">mdi-file</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <div>{{ itemAll.title }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="itemAll.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-tab-item>
              <v-tab-item class="py-1">
                <v-list-item v-for="(itemCampaign, iCampaign) in listCampaign" :key="iCampaign" two-line>
                  <v-list-item-icon>
                    <v-icon>mdi-cube</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div>{{ itemCampaign.title }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="itemCampaign.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-tab-item>
              <v-tab-item class="py-1">
                <v-list-item v-for="(itemInvoice, iInvoice) in listInvoice" :key="iInvoice" two-line title="Invoice">
                  <v-list-item-icon>
                    <v-icon>mdi-file</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div>{{ itemInvoice.title }}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="itemInvoice.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchBox: '',
    searchText: '',
    notFound: false,
    loading: false,
    searchResult: [],
    tab: null,
    listCampaign: [],
    listInvoice: [],
  }),

  methods: {
    async searchFind() {
      this.loading = true
      this.notFound = false
      var q = {
        search: this.$route.query.search,
      }
      await this.$axios
        .get('search', {
          params: q,
          cacheConfig: true,
        })
        .then(response => {
          this.searchResult = response.data.data

          this.listCampaign = this.searchResult.filter(rec => {
            if (rec.type == 'Campaign') {
              return rec
            }
          })
          this.listInvoice = this.searchResult.filter(rec => {
            if (rec.type == 'Invoice') {
              return rec
            }
          })

          if (this.searchResult.length == 0) {
            this.notFound = true
          }
        })
        .catch(error => {
          // console.log(error)
          this.showAlert('error', error.message)
        })
        .then(() => {
          this.loading = false
        })
    },
  },

  updated() {},

  mounted() {
    this.requiredLogin()
    this.setTitle('Search')
    this.searchText = this.$route.query.search
    this.searchFind()
  },
}
</script>